import type { ParentComponent } from "solid-js";
import type { FormValue } from "~/types/common";

import { createContext, useContext } from "solid-js";
import { createStore } from "solid-js/store";

export type FormStorage = [
  { values: Record<string, FormValue> },
  {
    setValue?: (name: string, value: FormValue) => void;
    unsetValue?: (name: string) => void;
  },
];

const FormContext = createContext<FormStorage>([{ values: {} }, {}]);

export const FormContextProvider: ParentComponent<{
  values?: Record<string, FormValue>;
}> = (props) => {
  const [state, setState] = createStore({
      // eslint-disable-next-line solid/reactivity
      values: props.values || {},
    }),
    store: FormStorage = [
      state,
      {
        setValue(name: string, value: FormValue) {
          setState("values", (prev) => ({ ...prev, [name]: value }));
        },

        unsetValue(name: string) {
          setState("values", name, undefined);
        },
      },
    ];

  return (
    <FormContext.Provider value={store}>{props.children}</FormContext.Provider>
  );
};

export function useFormContext() {
  return useContext(FormContext);
}
